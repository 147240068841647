import React from 'react';
import { graphql, Script } from 'gatsby';
import PropTypes from 'prop-types';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import Navbar from '../components/navbar';
import { Footer } from '../components/footer';
import SEO from '../SEO';
import sanityConfig from '../../config/sanity';

import '../styles/global.css';
import { pageMeta, urlFor } from '../utils/common';
import CardPengaduan from '../components/cardPengaduan';
import Wrapper from '../components/Wrapper';
import Button from '../components/button/Button';
import useSmartScript from '../hooks/useSmartScript';
import { ONELINK_SCRIPT_URL } from '../configs/appsflyer';
import GTM_TRACKERS from '../../constants/gtm-trackers';
import { formatDate, isAfter } from '../utils/datetime';

export const query = graphql`
  {
    allSanityPromo(
      filter: { isActive: { eq: true }, subCategories: { elemMatch: { title: { nin: ["Affiliate", "Privileges"] } } }, publishedAt: { ne: null } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        id
        lang
        category
        slug {
          current
        }
        title
        mainImageURL
        mainImage {
          asset {
            _id
          }
        }
        mainImageMobile {
          asset {
            _id
          }
        }
        publishedAt
        _rawBody
        author {
          id
          name
        }
        buttonCtas {
          url
          text
          scheme
          _type
          _rawCtaImage
          _key
          ctaImage {
            _key
            _type
            asset {
              _id
              id
            }
          }
        }
        list {
          _id
          _type
          label
          list {
            ... on SanityPromo {
              _id
              title
              category
              subCategories {
                _id
                title
              }
              author {
                name
              }
              lang
              mainImage {
                asset {
                  _id
                }
              }
              slug {
                current
              }
              publishedAt(locale: "id", formatString: "DD MMMM YYYY")
            }
            ... on SanityBlog {
              _id
              title
              category
              subCategories {
                _id
                title
              }
              author {
                name
              }
              lang
              mainImage {
                asset {
                  _id
                }
              }
              slug {
                current
              }
              publishedAt(locale: "id", formatString: "DD MMMM YYYY")
            }
          }
        }
      }
    }
  }
`;

const PromoCard = ({ promo }) => {
  if (
    promo.publishedAt === null
    || isAfter({ date: promo.publishedAt })
  ) {
    return null;
  }

  const cardImage = getGatsbyImageData(
    promo.mainImage,
    {
      placeholder: 'blurred',
      layout: 'constrained',
    },
    sanityConfig,
  );

  return (
    <div
      className="p-3 lg:px-0 lg:py-5 text-primary"
    >
      <SEO
        title="Semua Promo"
        metaDescription={pageMeta.mainDesc}
        cannonicalPath="promo"
      />
      <div className="w-full mb-3">
        <GatsbyImage
          image={cardImage}
          className="lg:rounded-xl"
          loading="lazy"
          title={promo.title}
        />
      </div>
      <div className="w-full">
        <a
          className="lg:text-base font-bold hover:no-underline"
          href={`/promo/${promo.slug.current}`}
        >
          <h1 className="w-full mb-3">{promo.title}</h1>
        </a>
        <div className="text-primary lg:text-sm">
          {promo.author ? promo.author.name : 'Komunal Indonesia'}
        </div>
        <div className="mb-5 lg:text-sm">{formatDate({ date: promo.publishedAt })}</div>
      </div>
    </div>
  );
};

PromoCard.propTypes = {
  promo: PropTypes.object.isRequired,
};

export default function PromoPage({ data }) {
  const { allSanityPromo } = data;
  const { smartScriptUrl, setIsSmartScriptLoaded } = useSmartScript('promo');

  return (
    <Wrapper>
      <SEO
        title="Promo"
        metaDescription={pageMeta.mainDesc}
        metaImage={urlFor(allSanityPromo.nodes[0].mainImage).url()}
        type="website"
        cannonicalPath="promo"
      />
      <Script src={ONELINK_SCRIPT_URL} onLoad={() => { setIsSmartScriptLoaded(true); }} />
      <Navbar />
      <div className="flex flex-col lg:flex-row w-full bg-blue-light40 relative">
        <div className="flex flex-col items-center md:items-start gap-y-4 z-20 px-4 md:px-8 lg:px-16 pt-16 pb-[340px] md:py-12 lg:py-20">
          <div className="w-[157px] lg:w-[249px]">
            <StaticImage
              src="../images/home/campaign.webp"
              alt="#PilihYangTepat"
              objectFit="contain"
            />
          </div>
          <h1 className="md:w-60 lg:w-[480px] text-3xl md:text-lg lg:text-4xl text-white text-center md:text-left font-semibold">
            Promo & Cashback untuk Ekstra Cuan!
          </h1>
          <div className={`flex ${GTM_TRACKERS.PROMO.KLIK_DISINI}`}>
            <Button
              href={smartScriptUrl}
              text="KLIK DI SINI"
              className="px-16 py-2"
            />
          </div>
        </div>
        <div className="md:hidden z-10 absolute bottom-0">
          <StaticImage
            src="../images/home/banner-mobile.webp"
            alt="Banner"
            objectFit="contain"
          />
        </div>
        <div className="hidden md:block md:w-[560px] lg:w-[840px] z-10 absolute bottom-0 right-0">
          <StaticImage
            src="../images/home/banner.webp"
            alt="Banner"
            objectFit="contain"
          />
        </div>
      </div>
      <div className="flex flex-col items-center px-4 lg:mx-auto lg:grid lg:grid-cols-3 lg:gap-x-6 lg:mt-10">
        {allSanityPromo.nodes.map((promo) => (
          <PromoCard key={promo.id} promo={promo} />
        ))}
      </div>
      <CardPengaduan />
      <Footer />
    </Wrapper>
  );
}

PromoPage.propTypes = {
  data: PropTypes.object.isRequired,
};
